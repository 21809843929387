/*
The main SCSS file for everything, yep
*/

@import "vendors/vendors";

@import "main";
@import "base";
@import "layout";
@import "syntax-highlighting";

/* Hero image */

/* Extra small devices (phones, less than 768px) */
@media screen{
    .hero-img{
        width:100%;
        background-repeat: no-repeat;
        height: 350px;
        background-size: cover;
        position: relative;
        background-image:url('/assets/images/bgtemp_1366.jpg');
        background-position: center center;
    }
}

.hero-list{
    position: absolute;
    display: block;
    overflow: hidden;
    width: 100%;
    height: 70%;
    left: 10%;
    top: 25%;
    // if nav is not sticky anymore than top:15%

    div{
        //wrap divs around the content
        display: table;
    }

    ul{
        list-style-type: none;
    }
    // align with the list
    .btn{
        margin-left: 30px;
    }
}

.hero-li-main{
    //padding: 15px 0 1px 5px;
    margin: 0px;
    white-space: nowrap;
    background-color: #2196F3;
    color: white;
    padding:5px !important;
    background-color: rgba(33, 150, 243, 0.81) !important;

    h1{
        font-weight: 500;
        //font-size: 1.3em;
        //padding: 10px;
        text-transform: uppercase;
        margin: 0;
        font-size: 0.8em;
    }
}

.hero-normal{
    position:absolute;
    font-size: 14px;
    padding: 0px;
    margin: 0px;
    border-width: 0px;
    line-height: 24px;
    white-space: nowrap;
}

.hero-middle-text{
    color:white;
    font-weight:bold;
    font-size:1.5em;
}

h2.hero-middle-text:after{
    content: "";
    display: block;
    border-top: 1px solid #eeeeee;
    margin: 10px 0 10px 0;
}

.texty{
    color: white;
    font-size:1.1em;
    i{
        font-size:1.2em;
    }
}

/* Language Switch */


// link language switch

a:hover.a-lang-switch, a.active.a-lang-switch {
    text-decoration: none;
    border-bottom: none;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
}

/* Sections */

.sect-light-blue{
    background-color:#f4fafe;
}

.sect-blue{
    background-color: #7EB1DC;
}

.sect-gray{
    background-color: #fafafa;
}

.sect-gray-medium{
  background-color: #f1f1f2;
}

.sect-skills{
    background-image: url('/assets/images/wordart2000.png');
    background-repeat: repeat;
}

.sect-footer-layer1{
  //background-color:#0E395C;
   @extend .sect-gray-medium;
   padding:30px;

   .footer-heading{
     color: #E47B3C;
   }
}
.sect-footer-layer2{
  @extend .sect-blue;
  padding:30px;
  color: #094C83;
  font-weight: 500;

  .footer-heading{
     color: #E3EEFF;
   }
   h4.footer-heading{
     padding-bottom:0;
   }
}
.sect-footer-layer3{
  background-color:#08589A;
  padding-top:15px;
  padding-bottom:15px;
  color: #7EB1DC;
}

/* Page title */

.page-title{
    text-align: center;
    padding-bottom:30px;
    padding-top:20px;
}

.page-title > :first-child{
    font-weight:bold;
}

/*Card style*/

.card-header,
.card-footer{
    text-align: center;
}

.card-header:after{
    content: "";
    display: block;
    border-top: 1px solid #eeeeee;
    margin: 10px 0 10px 0;
}

/* Icons */

.material-icons.md-18 {
  font-size: 18px; }
.material-icons.md-24 {
  font-size: 24px; }
.material-icons.md-36 {
  font-size: 36px; }
.material-icons.md-48 {
  font-size: 48px; }
.material-icons.md-dark {
  color: rgba(0, 0, 0, 0.54); }
.material-icons.md-dark.md-inactive {
  color: rgba(0, 0, 0, 0.26); }
.material-icons.md-light {
  color: white; }
.material-icons.md-light.md-inactive {
  color: rgba(255, 255, 255, 0.3); }
.material-icons.blue500 { color: $blue-500; }

/* Vertical alignment for divs with cols */

.vcenter {
    display: table-cell;
    vertical-align: middle;
    float: none;
}

/* Float card content */

.float-r{
  float:right;
}

/* if i want to add header shrink on scroll

header {
    width: 100%;
    height: 70px;
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    background-color:white;
    -webkit-transition: height 0.3s;
    -moz-transition: height 0.3s;
    -ms-transition: height 0.3s;
    -o-transition: height 0.3s;
    transition: height 0.3s;
}

header nav {
    display: inline-block;
    float: right;
}
header nav a {
    line-height: 150px;
    margin-left: 20px;
    color: #9fdbfc;
    font-weight: 700;
    font-size: 18px;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}
header nav a:hover {
    color: white;
}
header.smaller {
    height: 30px;
}
header.smaller nav a {
    line-height: 30px;
}

@media all and (max-width: 660px) {

    header nav {
        display: block;
        float: none;
        height: 30px;
        text-align: center;
        margin: 0 auto;
    }
    header nav a {
        line-height: 30px;
        margin: 0 10px;
    }
    header.smaller {
        height: 30px;
    }
    header.smaller nav {
        height: 30px;
    }
    header.smaller nav a {
        line-height: 30px;
    }
}
*/

/* Profiles */


// hexagon images
.profile-pic{
-webkit-clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
    height:180px;
}

@-moz-document url-prefix() {
    .profile-pic { border-radius:50%;}
}

:root .profile-pic{
    border-radius:50% \0/IE9; /* Only works in IE10 */
}

// circular images

.circular-image {
    display: block;
    position: relative;
    box-sizing: border-box;
    width: 100%;
    padding: 4px;
    background: #e4e4e4;
    border-radius: 50% 50% 50% 4px;
    max-width:150px;
}

.circular-image img {
    display: block;
    position: relative;
    width: 100%;
    height: auto;
    z-index: 0;
    border-radius: 50%;
}

.profile-heading{
    font-weight: 500;
    padding-top: 15px;
    margin-bottom: 0;
}

// Profile list of skills (for private website)
.profile-list{
    text-align:center;
    ul{
        list-style-type: none;
        margin-left:0;
    }
}

.profile-container{
    padding-top:10px;
    padding-bottom:60px;
}

/* Progress */

.progress-label{
    text-transform: uppercase;
    color:$grey-color;
}
// a custom class that was partly taken from bootstraps slider-handle class
.progress-handle{
    position: absolute;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    margin-left: -10px;
    margin-top: -7px;
    background-color: #337ab7;
    background-image: -webkit-linear-gradient(top, #149bdf 0%, #0480be 100%);
    background-image: -o-linear-gradient(top, #149bdf 0%, #0480be 100%);
    background-image: linear-gradient(to bottom, #149bdf 0%, #0480be 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff149bdf', endColorstr='#ff0480be', GradientType=0);
    filter: none;
    -webkit-box-shadow: inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.05);
    box-shadow: inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.05);
    border: 0px solid transparent;
}

.progress-wrapper{
  position: relative;
}

/* Services */

.service-image{
    height:295px;
    padding-bottom:60px;
}

/* Footer */

.footer-icons{text-align:center;}
.footer-icons i{
  color: #E47B3C;
  display: block;
  padding-bottom: 15px;
}

.table-padding{
  padding-left: 15px;
}

.table-padding-major{
  padding-left: 30px;
}

.chatting{
    position: absolute;
    margin: auto;
    display: block;
    left: 0;
    right: 0;
    z-index: 0;
    width: 150px;
    top: -20px;
}

.form-centered{
  width: 50%;
  display: block;
  margin: 0 auto;
  margin-bottom:40px;
}

a.btn-orange{
    background-color: #E47B3C !important;
    color: #f1f1f2 !important;
    margin: 0 auto;
    display: table;
}

a:hover.btn-orange, a:focus.btn-orange{
    background-color: darken( #E47B3C, 15% ) !important;
    border-bottom: none;
}

a.btn-light{
    background-color: #E3EEFF !important;
    color: #E47B3C !important;
    margin: 0 auto;
}

a:hover.btn-light, a:focus.btn-light{
    background-color: #f1f1f2 !important;
    color: darken( #E47B3C, 15% ) !important;
    border-bottom: none;
}

/**
/ Individual sections and elements
**/

/* Latest Project */

.vcenter{
  display: inline-block;
  vertical-align: middle;
}

/* customized buttons */

.skills-btn{
    position: absolute;
    left:0;
    right:0;
    margin-top:30px
}

/* icons */

.align-bottom{
    vertical-align: text-bottom;
}

/**
/ Media queries
**/

/* Small devices (tablets, 768px and up) */
@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max){
      .hero-img{height: 450px;}

      .hero-li-main h1,
      .hero-middle-text{
          font-size:1.45em;
      }

      .hero-li h3{
          font-size:1.3em;
      }

      .hero-li-main{
          padding:3px 10px !important;
      }

    .hero-list{
        width: calc(80% - 60px);
    }

    .circular-image {
        display: block;
        position: relative;
        box-sizing: border-box;
        width: 100%;
        padding: 10px;
        background: #e4e4e4;
        border-radius: 50% 50% 50% 4px;
        max-width: none;
    }

    .circular-image img {
        display: block;
        position: relative;
        width: 100%;
        height: auto;
        z-index: 0;
        border-radius: 50%;
    }

    // Profile list of skills (for private website)
    .profile-list{
        text-align:left;
        ul{
            list-style-type: disc;
        }
        h1{
            font-size: 36px;
        }
    }

    .profile-container{
        padding-top:50px;
        padding-bottom:100px;
    }

    .texty,
    .texty i{
        font-size:24px;
    }

}

/* Medium devices (desktops, 992px and up) */
@media (min-width: $screen-md-min) {
      .hero-img{height: 450px;}

      .hero-li-main h1,
      .hero-middle-text{
          font-size:1.9em;
      }

      .hero-li h3{
          font-size:1.3em;
      }

      .hero-li-main{
          padding:3px 10px !important;
      }

    .hero-list{
        width: calc(80% - 60px);
    }

    .circular-image {
        display: block;
        position: relative;
        box-sizing: border-box;
        width: 100%;
        padding: 10px;
        background: #e4e4e4;
        border-radius: 50% 50% 50% 4px;
        max-width: none;
    }

    .circular-image img {
        display: block;
        position: relative;
        width: 100%;
        height: auto;
        z-index: 0;
        border-radius: 50%;
    }

    // Profile list of skills (for private website)
    .profile-list{
        text-align:left;
        ul{
            list-style-type: disc;
        }
        h1{
            font-size: 36px;
        }
    }

    .profile-container{
        padding-top:50px;
        padding-bottom:100px;
    }

    .texty,
    .texty i{
        font-size:24px;
    }

 }

@media screen and (min-width: 1367px) {
    .hero-img{
        width:100%;
        background-repeat: no-repeat;
        height: 590px;
        background-size: cover;
        position: relative;
        background-image:url('/assets/images/bgtemp_1920.jpg');
        background-position: center center;
    }

      .hero-li-main h1,
      .hero-middle-text{
          font-size:2.2em;
      }

      .hero-li h3{
          font-size:1.3em;
      }

      .hero-li-main{
          padding:3px 10px !important;
      }

    .hero-list{
        width: calc(80% - 60px);
    }

    .circular-image {
        display: block;
        position: relative;
        box-sizing: border-box;
        width: 100%;
        padding: 10px;
        background: #e4e4e4;
        border-radius: 50% 50% 50% 4px;
        max-width: none;
    }

    .circular-image img {
        display: block;
        position: relative;
        width: 100%;
        height: auto;
        z-index: 0;
        border-radius: 50%;
    }


    // Profile list of skills (for private website)
    .profile-list{
        text-align:left;
        ul{
            list-style-type: disc;
        }
        h1{
            font-size: 36px;
        }
    }

    .profile-container{
        padding-top:50px;
        padding-bottom:100px;
    }

    .hero-li-main{
        padding:3px 10px !important;
    }

    .texty,
    .texty i{
        font-size:24px;
    }

}

@media screen and (min-width: 1920px) {
    .hero-img{
        width:100%;
        background-repeat: no-repeat;
        height: 620px;
        background-size: cover;
        position: relative;
        background-image:url('/assets/images/bgtemp_full.jpg');
        background-position: center center;
    }

      .hero-li-main h1,
      .hero-middle-text{
          font-size:2.2em;
      }

      .hero-li h3{
          font-size:1.3em;
      }

      .hero-li-main{
          padding:3px 10px !important;
      }

    .hero-list{
        width: calc(80% - 60px);
    }

    .circular-image {
        display: block;
        position: relative;
        box-sizing: border-box;
        width: 100%;
        padding: 10px;
        background: #e4e4e4;
        border-radius: 50% 50% 50% 4px;
        max-width: none;
    }

    .circular-image img {
        display: block;
        position: relative;
        width: 100%;
        height: auto;
        z-index: 0;
        border-radius: 50%;
    }

    // Profile list of skills (for private website)
    .profile-list{
        text-align:left;
        ul{
            list-style-type: disc;
        }
    }

    .profile-container{
        padding-top:50px;
        padding-bottom:100px;
    }

    .hero-li-main{
        padding:3px 10px !important;
    }

    .texty,
    .texty i{
        font-size:24px;
    }

}
