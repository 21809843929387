/**
 * Site header
 */
.site-header {
    position:fixed;
    border-top: 5px solid $grey-color-dark;
    min-height: 56px;
    background-color: rgba(255,255,255,0.90);

    // Positioning context for the mobile navigation icon
    //position: relative;
    
    // For sticky navigation
    width: 100%;
    //overflow: hidden;
    position: fixed;
    top: 0;
    z-index: 999;
}

.site-title {
    font-size: 26px;
    line-height: 56px;
    letter-spacing: -1px;
    margin-bottom: 0;
    float: left;

    img{
        width:140px;
    }

    &,
    &:visited {
        color: $grey-color-dark;
    }
    &:hover{
        border-bottom: none;
        
        img{
            -webkit-filter: opacity(70%);
            filter: opacity(70%);
        }
    }
}

.site-nav {
    //float: right;
    float:left;
    line-height: 56px;
    margin-left:130px;

    .menu-icon {
        display: none;
    }

    .page-link {
        color: $text-color;
        line-height: $base-line-height;

        // Gaps between nav items, but not on the last one
        &:not(:last-child) {
            margin-right: 20px;
        }
    }

    a:hover,
    a:active,
    a:focus{
        border-bottom:none;
    }  

    a.page-link:hover,
    a.page-link:active,
    a.page-link:focus{
        color:$blue-500;
        border-bottom:none;
    }

    @include media-query($on-palm) {
        //position: absolute;
        position: fixed;
        top: 20px;
        right: $spacing-unit / 2;
        background-color: $background-color;
        border: 1px solid $grey-color-light;
        border-radius: 5px;
        text-align: right;

        .menu-icon {
            display: block;
            float: right;
            width: 36px;
            height: 26px;
            line-height: 0;
            padding-top: 6px;
            text-align: center;

            > svg {
                width: 18px;
                height: 15px;

                path {
                    fill: $grey-color-dark;
                }
            }
        }

        .trigger {
            clear: both;
            display: none;
        }

        &:hover .trigger {
            display: block;
            padding-bottom: 5px;
        }

        .page-link {
            display: block;
            padding: 5px 10px;

            &:not(:last-child) {
                margin-right: 0;
            }
            margin-left: 20px;
        }
    }
}

.language-switch{
    float: right;
    margin-left: 40px;
    margin-right:50px;
    line-height: 56px;
}

.language-switch-btn{
    margin: 3px;
    padding: 3px;
    margin-top: 15px;
    float: right;
    margin-right: 0;
    //margin-left:90px;
}

.language-menu{
    top: 42px;
    //right: -130px;
    right: -42px;
    left: inherit;
    min-width:80px;
}

.dropdown-menu.language-menu{
    margin: 2px 0 0;
    float:right;
    position: relative;
    vertical-align: middle;
}



/**
 * Site footer
 */
.site-footer {
    //border-top: 1px solid $grey-color-light;
    padding: $spacing-unit 0;
}

.footer-heading {
    font-size: 18px;
    margin-bottom: $spacing-unit / 2;
    padding-bottom: $spacing-unit;
    text-align: center;
    //color:#4d4d4d;
    @extend .heading-style2;
}

.plane-list,
.plane-list ul,
.contact-list,
.social-media-list {
    list-style: none;
    margin-left: 0;
}

.footer-col-wrapper {
    font-size: 15px;
    color: $grey-color;
    margin-left: -$spacing-unit / 2;
    @extend %clearfix;
}

.footer-col {
    float: left;
    text-align: center;
    margin-bottom: $spacing-unit / 2;
    padding-left: $spacing-unit / 2;
}

.footer-col-1 {
    width: -webkit-calc(35% - (#{$spacing-unit} / 2));
    width:         calc(35% - (#{$spacing-unit} / 2));
}

.footer-col-2 {
    width: -webkit-calc(20% - (#{$spacing-unit} / 2));
    width:         calc(20% - (#{$spacing-unit} / 2));
}

.footer-col-3 {
    width: -webkit-calc(45% - (#{$spacing-unit} / 2));
    width:         calc(45% - (#{$spacing-unit} / 2));
}

@include media-query($on-laptop) {
    .footer-col-1,
    .footer-col-2 {
        width: -webkit-calc(50% - (#{$spacing-unit} / 2));
        width:         calc(50% - (#{$spacing-unit} / 2));
    }

    .footer-col-3 {
        width: -webkit-calc(100% - (#{$spacing-unit} / 2));
        width:         calc(100% - (#{$spacing-unit} / 2));
    }
}

@include media-query($on-palm) {
    .footer-col {
        float: none;
        width: -webkit-calc(100% - (#{$spacing-unit} / 2));
        width:         calc(100% - (#{$spacing-unit} / 2));
    }
}



/**
 * Page content
 */
 
.page-content {
    //padding: $spacing-unit 0; creates some white area
    position:relative;
    margin-top:72px;
}

.page-heading {
    font-size: 18px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.heading-style2{
    font-size: 18px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.heading-style3{
    font-size: 18px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 2px;
    border-bottom: 3px solid #E47B3C;
    margin-bottom: 20px;
}

.heading-light{
    color: #E3EEFF;
}

.post-list {
    margin-left: 0;
    list-style: none;

    > li {
        margin-bottom: $spacing-unit;
    }
}

.post-meta {
    font-size: $small-font-size;
    color: $grey-color;
}

.post-link {
    display: block;
    font-size: 24px;
}

.inline-element{
  display:inline-block;
}



/**
 * Posts
 */
.post-header {
    margin-bottom: $spacing-unit;
}

.post-title {
    font-size: 42px;
    letter-spacing: -1px;
    line-height: 1;

    @include media-query($on-laptop) {
        font-size: 36px;
    }
}

.post-content {
    margin-bottom: $spacing-unit;

    h2 {
        font-size: 32px;

        @include media-query($on-laptop) {
            font-size: 28px;
        }
    }

    h3 {
        font-size: 26px;

        @include media-query($on-laptop) {
            font-size: 22px;
        }
    }

    h4 {
        font-size: 20px;

        @include media-query($on-laptop) {
            font-size: 18px;
        }
    }
}

section{
    padding-top: 60px;
    padding-bottom: 50px;
    border-bottom: 1px solid #e5e5e5;
}


/**
/ Media queries
**/

/* Special media query only for laguage switch to adjust the collapsin menu for small devices */
@media (max-width: 600px){
    .language-switch-btn{
        margin-right: 125px;
        margin-left:5px;
    }

    .language-menu{
        right: -45px;
    }

    nav.site-nav{
        a:hover,
        a:active,
        a:focus{
            border-bottom:none;
        }  
    }

}

/* Small devices (tablets, 768px and up) */
@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max){

    section{
        padding-top: 100px;
        padding-bottom: 100px;
        border-bottom: 1px solid #e5e5e5;
    }

    .site-nav{
        float:right;
        margin-left:0;
    }

    .site-title{
        img{
            width:200px;
        }
    }

    .language-switch{
        margin-right:0;
    }

}

/* Medium devices (desktops, 992px and up) */
@media (min-width: $screen-md-min) {

    section{
        padding-top: 100px;
        padding-bottom: 100px;
        border-bottom: 1px solid #e5e5e5;
    }

    .site-nav{
        float:right;
        margin-left:0;
    }

    .site-title{
        img{
            width:200px;
        }
    }

    .language-switch{
        margin-right:0;
    }

 }

@media screen and (min-width: 1367px) {

    section{
        padding-top: 100px;
        padding-bottom: 100px;
        border-bottom: 1px solid #e5e5e5;
    }

    .site-nav{
        float:right;
        margin-left:0;
    }

    .site-title{
        img{
            width:200px;
        }
    }

    .language-switch{
        margin-right:0;
    }

}

@media screen and (min-width: 1920px) {

    section{
        padding-top: 100px;
        padding-bottom: 100px;
        border-bottom: 1px solid #e5e5e5;
    }

    .site-nav{
        float:right;
        margin-left:0;
    }

    .site-title{
        img{
            width:200px;
        }
    }

    .language-switch{
        margin-right:0;
    }

}